import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BG from "../assets/2015/11/MAZD0294-Mazda-Foundation-Website-V4_03.jpg"

const FoundationObjectives = () => (
  <Layout page="objectives">
    <SEO title="Foundation Objectives" />
    <div
      id="foundation-objectives"
      style={{
        background: `url(${BG}) repeat`,
      }}
    >
      <div id="title" className="green-bg vertical-padding-smaller center">
        <div className="container">
          <div className="horizontal-padding">
            <h1>FOUNDATION OBJECTIVES</h1>
          </div>
        </div>
      </div>
      <div className="vertical-padding-small">
        <div className="container">
          <div id="about" className="horizontal-padding">
            <p>
              The Mazda Foundation’s aims and objectives include (but are not limited to) the provision of financial aid to funds, authorities and institutions which qualify for income tax deductibility for gifts and which provide:
              <br /><br />
              <ul>
                <li>
                  assistance to young people through education and employment skills development, with particular emphasis on children from disadvantaged backgrounds.
                </li>
                <li>
                  assistance in the advancement of education and achievement of excellence at all levels in the community.
                </li>
                <li>
                  assistance in the development of technology, to help improve Australia’s international competitiveness.
                </li>
                <li>
                  assistance towards the maintenance and improvement of the natural environment.
                </li>
                <li>
                  assistance to address community needs identified by the Mazda Foundation from time to time.
                </li>
                <li>
                  assistance in research, feasibility, surveys and other background work in relation to any of the above aims and objectives.
                </li>
                <li>
                  Substantial contributions to the Mazda Foundation have been made by Mazda Motor Corporation, Japan and Mazda Australia. Further contributions continue to be made by Mazda Australia, supported by the Mazda Dealer Network.
                </li>
              </ul>
              Donations to the Foundation, which are tax deductible, are also invited from individuals and organisations sharing the philosophy of the Mazda Foundation.
              <br /><br />
              Mazda Foundation has been endorsed by the Australian Taxation Office as a deductible gift recipient and cheques should be made payable to “Mazda Foundation”.
            </p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default FoundationObjectives
